import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import CourseworkDisclaimer from "../components/CourseworkDisclaimer"

const MergesortPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>CSCE 221: Mergesort</h1>
            {CourseworkDisclaimer()}
            <p>CSCE 221 is Introduction to Data Structures and Algorithms. It is a very fun course; so much so that I went on to TA this course four times. You can read more on the <a href="/gradescope">TA Page</a></p>
            <p>The assignment I want to discuss was about sorting algorithms. In this assignment we were asked to implement and compare four sorting algorithms in C++: Bubble Sort, Heap Sort, Quick Sort, and Merge Sort. We compared the runtimes on varying input types and sizes, checked that the experimental results matched the expected Big(O) behavior, and discussed the various algorithms. Its fairly standard computer science coursework.</p>
            <p>What wasn't standard was my implementation of merge sort. A reasonable implementation of merge sort could use up to O(N/2) overhead, where the half the list is copied into another block of memory. However, a really, really different solution is to copy the entire list into another block of memory for O(N) overhead. This may sound really dumb (and it probably is) but it has one key benefit: you can sort really really fast.</p>
            <p>The idea is to sort back and forth between the two blocks of memory, each of which contains the entire list. At each level of merge sort, the merging can happen from one block into a appropriate spot in the other list. Using pointers, this can all happen seamlessly. However, one big benefit emerges: you start packing memory into cache lines; rather than fetching arrays of smaller and smaller sizes from random places in memory, you can fetch the whole block. Its still O(NlogN), but it runs very fast.</p>
            <p>If you consider the base case to be the one or two element list, it can be shown that all sub-lists will recurse the same number of times. You have to be very careful and explicit about your pointers; but it can be done (and its correct).</p>

            <p>The following table contains the experimental runtime of the code I wrote:</p>
            <table>
                <tr>
                    <th>(in ms)</th>
                    <th>Random Merge Sort</th>
                    <th>Random Quick Sort</th>
                    <th>Random Heap Sort</th>
                </tr>
                <tr>
                    <td>100000</td>
                    <td>14.1</td>
                    <td>23.8</td>
                    <td>223.9</td>
                </tr>
                <tr>
                    <td>1000000</td>
                    <td>164.3</td>
                    <td>319.2</td>
                    <td>2782.5</td>
                </tr>
                <tr>
                    <td>10000000</td>
                    <td>1460.2</td>
                    <td>6499.3</td>
                    <td>36713.6</td>
                </tr>
            </table>

            <p>Sometime in the near future I plan on adding an image explaining this process and revisiting this solution with an additional 3+ years of programming experience.</p>
            {FooterBar()}
        </div>
    )
}

export default MergesortPage